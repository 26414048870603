import React, { useState } from 'react'
import styled from 'styled-components'

import { useToolbarState } from 'reakit/Toolbar'

import { Box } from 'reakit/Box'

import { Provider } from 'reakit/Provider'

import * as system from 'reakit-system-bootstrap'

import MobileTwoButtons from './MobileTwoButtons'
import { theme } from 'styled-tools'
import { navigate } from 'gatsby'
import MoldalFaleConosco from '../Modal/ModalFaleConosco'

const Wrapper = styled.div`
	@media (min-width: 1024px) {
		display: none;
	}
`

// TODO: abstrair
const OptionText = styled.p`
	font-size: 13px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.23;
	letter-spacing: normal;
	text-align: left;
	color: ${theme('colors.white')};
	margin-left: 6px;
	line-height: 16px;
	/* margin-left: 0.5em; */
`

const BlackButton = styled(Box)`
	&& {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 80%;
		padding: 8px 0;
		background-image: linear-gradient(#3a3938, #3a3938);
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: 0% 100%;

		:not(:last-of-type) {
			border-bottom: 1px solid ${({ isMedic }) => (isMedic ? '#fff' : '#000')};
		}

		:active {
			transition: background-size 0.3s;
			background-size: 100% 100%;
		}
	}
`

// TODO: abstrair
const IconContainer = styled.img`
	&& {
		width: 25px;
		height: 25px;
		/* color: ${theme('colors.primary')}; */
	}
`

// TODO: abstrair
const BlackBackground = styled.div`
	background-color: ${({ isMedic }) => theme(isMedic ? 'colors.gray7b' : 'colors.gray1')};
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`

/**
 * Componente dos 2 botões coloridos + 3 botões pretos que aparecem só na home
 */
export default function HomeOnly({ urlResults, contatos, contatosAgendar, menuItens, isMedic }) {
	const homeMenu = menuItens.filter(item => item.showMobileHome)
	const toolbar = useToolbarState()
	const [openContactUsModal, setOpenContactUsModal] = useState(false)

	return (
		<Provider unstable_system={system}>
			<Wrapper>
				<MoldalFaleConosco open={openContactUsModal} onClose={() => setOpenContactUsModal(false)} />
				<MobileTwoButtons toolbar={toolbar} type='cliente' contatos={contatos} contatosAgendar={contatosAgendar} />
				<BlackBackground isMedic={isMedic}>
					{homeMenu.map(item => (
						<>
							<BlackButton
								isMedic={isMedic}
								key={item.text}
								onClick={() =>
									typeof window !== 'undefined' && item.href.length > 0
										? window.open(item.href, item.target)
										: item.to.length > 0
										? navigate(item.to)
										: eval(item.actionClick)
								}
							>
								<IconContainer src={item.svg} />
								<OptionText>{item.text}</OptionText>
							</BlackButton>
						</>
					))}
				</BlackBackground>
			</Wrapper>
		</Provider>
	)
}
